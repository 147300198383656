import { React, Link, StatePool, useEffect } from "../../imports/imports-packege";
import NavigationStyle from "./NavigationStyle.module.scss";
import EditContentTabs from "./EditContentTabs";
import TranslateBuilderText from '../TranslatePagesElement/translate-constructor.json';

const ActiveTabNav = StatePool.createState('app');

const Navigation = () => {
    const [activeTab, setActiveTab] = StatePool.useState(ActiveTabNav);

    const handleActiveTab = (tabName) => {
        setActiveTab(tabName);
        localStorage.setItem('activeTab', tabName);
    }

    let queryParams = new URLSearchParams(window.location.search);
    const translateQueryParameter = queryParams.get('ln') !== 'en' &&
                                    queryParams.get('ln') !== 'ua' ? 'en' : queryParams.get('ln');
    let keitaro_id = queryParams.get('keitaro_id');
    let onesignal_id = queryParams.get('onesignal_id');
    let type_land = queryParams.get('type_land');
    let builder_mode = queryParams.get('builder_mode');
    let paramsString = `keitaro_id=${keitaro_id}&onesignal_id=${onesignal_id}&type_land=${type_land}&builder_mode=${builder_mode}`;
    if (!queryParams.has('ln') || (queryParams.get('ln') !== 'en' && queryParams.get('ln') !== 'ua')) {
        queryParams.set('ln', translateQueryParameter);
        window.location.href = `https://${window.location.hostname}${window.location.pathname}?${queryParams.toString()}`;
    }

    useEffect(() => {
        let savedTab = localStorage.getItem('activeTab');
        let currentTab = getCurrentTabFromUrl();

        if (currentTab) {
            setActiveTab(currentTab);
            localStorage.setItem('activeTab', currentTab);
        } else if (savedTab) {
            setActiveTab(savedTab);
        }
    }, [setActiveTab]);
    const getCurrentTabFromUrl = () => {
        const path = window.location.pathname;
        if (path.startsWith("/parameters")) return "parameters";
        if (path.startsWith("/reviews")) return "review";
        return "app";
    }

    return (
        <div className={NavigationStyle.NavigationSection}>
            <div className={NavigationStyle.NavigationLinkContainer}>
                <ul className={NavigationStyle.NavigationLinkList}>
                    <li className={NavigationStyle.NavigationItemList}>
                        <Link
                            className={`${NavigationStyle.Link} ${activeTab === 'app' ? NavigationStyle.active : ''}`}
                            onClick={() => handleActiveTab('app')}
                            to={`/?keitaro_id=${keitaro_id}&ln=${translateQueryParameter}`} >
                            {TranslateBuilderText[translateQueryParameter]['menu']['app-page']}
                        </Link>
                    </li>
                    <li className={NavigationStyle.NavigationItemList}>
                        <Link
                            className={`${NavigationStyle.Link} ${activeTab === 'parameters' ? NavigationStyle.active : ''}`}
                            to={`/parameters?keitaro_id=${keitaro_id}&ln=${translateQueryParameter}`}
                            onClick={() => handleActiveTab('parameters')} >
                            {TranslateBuilderText[translateQueryParameter]['menu']['params-page']}
                        </Link>
                    </li>
                    <li className={NavigationStyle.NavigationItemList}>
                        <Link
                            className={`${NavigationStyle.Link} ${activeTab === 'review' ? NavigationStyle.active : ''}`}
                            to={`/reviews?keitaro_id=${keitaro_id}&ln=${translateQueryParameter}`}
                            onClick={() => handleActiveTab('review')} >
                            {TranslateBuilderText[translateQueryParameter]['menu']['reviews-page']}
                        </Link>
                    </li>
                </ul>
            </div>

            <div className={NavigationStyle.TabsEditContent}>
                <EditContentTabs />
            </div>
        </div>
    );
};

export default Navigation;
