import {React, StatePool, useEffect, useState} from "../../../imports/imports-packege";
import AppParametersStyle from "./AppParametersStyle.module.scss";
import {Input, ActionTitle, SelectList, TextAria} from "../../../imports/imports-components";
import {
    AppListCategories,
    AppListUserAge,
    AppCategories,
    AppRecommendedIcon,
    AppCategoriesEnVersion
} from "../../SelectOption/SelectOption";
import Switcher from "../../UI/Switch/Switch";
import { useData } from "../../Context/ContextApp";
import ProgressBar from "../../UI/Progress/ProgressBar";
import TranslateBuilderText from '../../TranslatePagesElement/translate-constructor.json';
import Select from 'react-select';
import  "../AppReview/AppReviewStyle.module.scss"
import getDescriptionText from './DescriptionText';
import Loader from "../AppReview/Loader";
import Switch from "react-switch";

const AppParameters = () => {
    let {data, updateData} = useData();

    const [translateCode, setTranslateCode] = useState('en');
    const queryParams = new URLSearchParams(window.location.search);
    const translateQueryParameter = queryParams.get('ln');
    const handleTranslate = (code_translate) => {
        setTranslateCode(code_translate)
    }
    useEffect(() => {
        handleTranslate(translateQueryParameter)
    }, [translateQueryParameter])

    const [isVerify, setIsVerify] = useState(data['verifiedDeveloper']);
    const handleVerifyDeveloper = (isVerify) => {
        setIsVerify(isVerify);
        updateData({
            ...data,
            verifiedDeveloper: isVerify
        })
    }

    const [authorApp, setAuthorApp] = useState('');
    const handleAuthorApp = (author) => {
        setAuthorApp(author);
        updateData({ ...data, author:  author});
    }
    useEffect(() => {
        setAuthorApp(data.author)
    }, [data.author])

    const [categoryApp, setCategoryApp] = useState(data.categories || '');
    useEffect(() => {
        updateData({ ...data, categories: categoryApp });
    }, [categoryApp]);
    const handleSelectTranslate = (categories) => {
        setCategoryApp(categories);
    }

    const [userAge, setUserAge] = useState(data.age || '');
    useEffect(() => {
        updateData({ ...data, age: userAge });
    }, [userAge]);
    const handleUserAge = (translate) => {
        setUserAge(translate);
    }

    const [appSize, setAppSize] = useState('');
    const handleAppSize = (size) => {
        setAppSize(size);
        updateData({ ...data, appSize:  size});
    }

    useEffect(() => {
        setAppSize(data.appSize)
    }, [data.appSize]);

    const [installCount, setInstallCount] = useState('');
    const handleInstallCount = (count) => {
        setInstallCount(count);
        updateData({ ...data, amountInstall:  count});
    }

    useEffect(() => {
        setInstallCount(data.amountInstall)
    }, [data.amountInstall]);

    const [landDescriptionTitle, setLandDescriptionTitle] = useState('');
    const handleDescriptionTitle = (descriptionTitle) => {
        setLandDescriptionTitle(descriptionTitle);
        updateData({ ...data, landDescriptionTitle:  descriptionTitle});
    }

    useEffect(() => {
        setLandDescriptionTitle(data.landDescriptionTitle);
    }, [data.landDescriptionTitle]);

    const [ratingApp, setRatingApp] = useState('');

    const handleRatingApp = (rating) => {
        setRatingApp(rating);
        updateData({ ...data, ratingStar: rating }); 
    }

    useEffect(() => {
        setRatingApp(data.ratingStar);
    }, [data.ratingStar]);

    const [amountComments, setAmountComments] = useState('');
    const handleAmountComments = (count) => {
        setAmountComments(count);
        updateData({ ...data, amountComments:  count});
    }

    useEffect(() => {
        setAmountComments(data.amountComments);
    }, [data.amountComments]);

    const [landDescription, setLandDescription] = useState('');
    const handleDescription = (description) => {
        setLandDescription(description);
        updateData({ ...data, landDescriptionText:  description});
    }

    useEffect(() => {
        setLandDescription(data.landDescriptionText);
    }, [data.landDescriptionText]);

    const [appMultiCategories, setAppMultiCategories] = useState(data.multiCategories || []);
    const handleDataUpdate = () => {
        updateData({ ...data, multiCategories: appMultiCategories });
    };
    useEffect(() => {
        if (appMultiCategories !== data.multiCategories) {
            handleDataUpdate();
        }
    }, [appMultiCategories, data.multiCategories, updateData]);

    useEffect(() => {
        if (data.categories_list) {
            let listCategories = data.categories_list.split(',');
            setAppMultiCategories(listCategories);
        }
    }, [data.categories_list]);

    const handleAppMultiCategories = (selectedOptions) => {
        const categoriesValues = selectedOptions.map(option => option.value);
        setAppMultiCategories(categoriesValues);
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            padding: 10,
            display: 'flex',
            alignItems: 'center',
        }),
    };

    const IconOption = ({ data, innerProps }) => (
        <div {...innerProps} className="icon-option">
            <img src={data.icon} alt="" style={{ marginRight: 10, width: 24, height: 24 }} />
            {data.label}
        </div>
    );

    const [recommendedApp, setRecommendedApp] = useState(data.recommendedApp || []);
    const handleDataAppUpdate = () => {
        if (recommendedApp !== data.recommendedApp) {
            updateData({...data, recommendedApp: recommendedApp});
        }
    }
    useEffect(() => {
        if (recommendedApp !== data.recommendedApp) {
            handleDataAppUpdate();
        }
    }, [recommendedApp]);

    const handleRecommendedApp = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        setRecommendedApp(selectedValues);
    };

    useEffect(() => {
        if (data.recommended_app) {
            let listSelectApp = data.recommended_app.split(',');
            setRecommendedApp(listSelectApp);
        }
    }, [data.recommended_app]);

    const [landName, setLandName] = useState(data.landName);
    const [languageLand, setLanguageName] = useState(data.langCode);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLandName(data.landName);
        setLanguageName(data.langCode);
    }, [data.langCode, data.landName])

    const [description, setDescription] = useState(null);
    const handleGenerateDescription = async () => {
        if (!data.landName) {
            return alert('Enter name PWA');
        }

        if (!data.langCode) {
            return alert('Select language PWA');
        }

        setLoading(true);
        const result = await getDescriptionText(landName, languageLand);
        if (result) {
            setDescription(result.description);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (description) {
            handleDescription(description);
        }
    },[description])

    return (
        <div className={AppParametersStyle.TabContent}>
            <div className={AppParametersStyle.ParametersItem}>
                <ActionTitle actionText={TranslateBuilderText[translateCode]['parameters-page']['author']}/>
                <Input
                    placeholder={"Play Dev"}
                    inputValue={handleAuthorApp}
                    valueSave={authorApp}/>
            </div>
            <div className={AppParametersStyle.ParametersItem}>
                <ActionTitle actionText={TranslateBuilderText[translateCode]['parameters-page']['verified-developer']}/>
                <Switch checked={isVerify} onChange={handleVerifyDeveloper} />
            </div>
            <div className={AppParametersStyle.ParametersItem}>
                <ActionTitle actionText={TranslateBuilderText[translateCode]['parameters-page']['categories']}/>
                <SelectList
                    optionList={AppListCategories}
                    getSelectValue={handleSelectTranslate}
                    value={categoryApp}
                />
            </div>
            <div className={AppParametersStyle.ParametersItem}>
                <ActionTitle actionText={TranslateBuilderText[translateCode]['parameters-page']['comments-number']}/>
                <Input
                    placeholder={"1034"}
                    inputValue={handleAmountComments}
                    valueSave={amountComments}/>
            </div>
            <div className={`${AppParametersStyle.ParametersItem} ${AppParametersStyle.DoubleSelect}`}>
                <div className={AppParametersStyle.List}>
                    <ActionTitle actionText={TranslateBuilderText[translateCode]['parameters-page']['age']}/>
                    <SelectList
                        optionList={AppListUserAge}
                        getSelectValue={handleUserAge}
                        value={userAge}
                    />
                </div>
                <div className={AppParametersStyle.List}>
                    <ActionTitle actionText={TranslateBuilderText[translateCode]['parameters-page']['size-app']} />
                    <Input
                        placeholder={"6"}
                        inputValue={handleAppSize}
                        valueSave={appSize}
                    />
                </div>
            </div>
            <div className={AppParametersStyle.ParametersItem}>
                <ActionTitle actionText={TranslateBuilderText[translateCode]['parameters-page']['install-number']} />
                <Input
                    placeholder={"2034"}
                    inputValue={handleInstallCount}
                    valueSave={installCount}/>
            </div>
            <div className={AppParametersStyle.ParametersItem}>
                <ActionTitle actionText={TranslateBuilderText[translateCode]['parameters-page']['title-description']} />
                <Input
                    placeholder={TranslateBuilderText[translateCode]['parameters-page']['title-placeholder']}
                    inputValue={handleDescriptionTitle}
                    valueSave={landDescriptionTitle}
                />
            </div>
            <div className={AppParametersStyle.ParametersItem}>
                <ActionTitle actionText={TranslateBuilderText[translateCode]['parameters-page']['content-description']}/>
                <TextAria
                    placeholderText={TranslateBuilderText[translateCode]['parameters-page']['content-placeholder']}
                    valueField={handleDescription}
                    valueSave={landDescription}/>

                <div
                    className={AppParametersStyle.Button}
                    onClick={handleGenerateDescription}
                    style={{backgroundColor: loading ? 'unset' : '#FF2A00'}}
                >
                    {loading ? <Loader /> : 'Generate description'}
                </div>
            </div>

            <div className={AppParametersStyle.ParametersItem}>
                <ActionTitle actionText={TranslateBuilderText[translateCode]['parameters-page']['rating-app']} />
                <Input
                    placeholder="4.2"
                    inputValue={handleRatingApp}
                    valueSave={ratingApp}
                />
            </div>

            <div className={AppParametersStyle.ParametersItem}>
                <ActionTitle actionText={"App rating chart"}/>
                {[0, 1, 2, 3, 4].map((index) => (
                    <ProgressBar key={index} index={index}/>
                ))}
            </div>

            <div className={AppParametersStyle.ParametersItem}>
                <ActionTitle actionText={TranslateBuilderText[translateCode]['parameters-page']['list-categories']}/>
                <Select
                    isSearchable={false}
                    readOnly
                    closeMenuOnSelect={false}
                    options={translateCode === 'ua'? AppCategories : AppCategoriesEnVersion}
                    isMulti
                    onChange={handleAppMultiCategories}
                    value={appMultiCategories.map(category => ({value: category, label: category}))}
                />
            </div>

            <div className={AppParametersStyle.ParametersItem}>
                <ActionTitle actionText={TranslateBuilderText[translateCode]['parameters-page']['recommended-app']}/>
                <Select
                    isSearchable={false}
                    readOnly
                    closeMenuOnSelect={false}
                    options={AppRecommendedIcon}
                    isMulti
                    value={recommendedApp && AppRecommendedIcon.filter(option => recommendedApp.includes(option.value))}
                    onChange={handleRecommendedApp}
                    components={{ Option: IconOption }}
                    styles={customStyles}
                />
            </div>
        </div>
    );
};

export default AppParameters;