import {React, useEffect, useState } from "../../../imports/imports-packege";
import Switch from "react-switch";
import { useData } from "../../Context/ContextApp";

const Switcher = ({ contextKey, isOn }) => {
    let {data, updateData} = useData();
    const [statusSwitcher, setStatusSwitcher] = useState(data[contextKey] !== false);

    const handleSwitcher = function(checked) {
        setStatusSwitcher(checked);
        isOn(checked)
    };

    useEffect(() => {
        updateData({ ...data, [contextKey]:  statusSwitcher});
        setStatusSwitcher(data[contextKey])
        console.log(data[contextKey], 'data[contextKey]')
    }, [statusSwitcher, contextKey])

    return (
        <div>
            <Switch onChange={handleSwitcher} checked={statusSwitcher} />
        </div>
    )
}

export default Switcher;